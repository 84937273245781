import MX from '../../assets/flags/mx.png';
import BR from '../../assets/flags/br.png';
import FR from '../../assets/flags/fr.png';
import US from '../../assets/flags/us.png';

export const locales = {
  es: {
    short: 'es',
    long: 'es-MX',
    name: 'Español',
    flag: MX || 'https://countryflagsapi.com/png/mx',
  },
  en: {
    short: 'en',
    long: 'en-US',
    name: 'English',
    flag: US || 'https://countryflagsapi.com/png/us',
  },
  fr: {
    short: 'fr',
    long: 'fr-FR',
    name: 'Français',
    flag: FR || 'https://countryflagsapi.com/png/fr',
  },
  pt: {
    short: 'pt',
    long: 'pt-BR',
    name: 'Português',
    flag: BR || 'https://countryflagsapi.com/png/br',
  },
};

export const shipping_status = [
  { value: 'canceled', label: { es: 'Cancelado', fr: 'Annulé', en: 'Canceled', pt: 'Cancelado' } },
  {
    value: 'in preparation',
    label: {
      es: 'En preparación',
      en: 'In preparation',
      fr: 'En préparation',
      pt: 'Em preparação',
    },
  },
  { value: 'send', label: { es: 'Envíado', en: 'Send', fr: 'Envoyé', pt: 'Enviado' } },
];

export const macrosTypes = [
  { value: 'naturaleza', label: { es: 'Naturaleza', en: 'Nature', fr: 'Nature', pt: 'Natureza' } },
  { value: 'rural', label: { es: 'Rural', en: 'Rural', fr: 'Rural', pt: 'Rural' } },
  { value: 'aventura', label: { es: 'Aventura', en: 'Adventure', fr: 'Aventure', pt: 'Aventura' } },
  {
    value: 'biocultural',
    label: { es: 'Biocultural', en: 'Biocultural', fr: 'Bioculturel', pt: 'Biocultural' },
  },
  {
    value: 'regenerativo',
    label: { es: 'Regenerativo', en: 'Regenerative', fr: 'Régénérateur', pt: 'Regenerativo' },
  },
  {
    value: 'agroturismo',
    label: { es: 'Agroturismo', en: 'Agritourism', fr: 'Agritourisme', pt: 'Agriturismo' },
  },
  {
    value: 'rutas gastronómicas',
    label: {
      es: 'Rutas gastronómicas',
      en: 'Gastronomic routes',
      fr: 'Routes gastronomiques',
      pt: 'Rotas gastronômicas',
    },
  },
  {
    value: 'ciencia y conservación',
    label: {
      es: 'Ciencia y conservación',
      en: 'Science and conservation',
      fr: 'Science et conservation',
      pt: 'Ciência e conservação',
    },
  },
];

export const secondaryActivities = [
  { value: 'forestal', label: { es: 'Forestal' } },
  { value: 'pesca', label: { es: 'Pesca' } },
  { value: 'agropecuario', label: { es: 'Agropecuario' } },
  { value: 'manufactura', label: { es: 'Manufactura' } },
  { value: 'procesos productivos', label: { es: 'Procesos productivos' } },
];

export const inclusivePolicies = [
  {
    value: 'mayor participación activa y liderazgo de mujeres',
    label: { es: 'Mayor participación activa y liderazgo de mujeres' },
  },
  {
    value: 'integración de pueblos indígenas y comunidades locales',
    label: { es: 'Integración de pueblos indígenas y comunidades locales' },
  },
  { value: 'personas con discapacidad', label: { es: 'Personas con discapacidad' } },
  { value: 'adultos mayores', label: { es: 'Adultos mayores' } },
  { value: 'LGBTT+', label: { es: 'LGBTT+' } },
];

export const transportationTypes = [
  {
    value: 'transporte de ida',
    label: {
      es: 'Transporte de ida',
      en: 'One way transportation',
      fr: 'Transport aller simple',
      pt: 'Transporte de ida',
    },
  },
  {
    value: 'transporte de regreso',
    label: {
      es: 'Transporte de regreso',
      en: 'Return transportation',
      fr: 'Transport de retour',
      pt: 'Transporte de retorno',
    },
  },
  {
    value: 'transporte redondo',
    label: {
      es: 'Transporte redondo',
      en: 'Round trip transportation',
      fr: 'Transport aller-retour',
      pt: 'Transporte de ida e volta',
    },
  },
];

export const weather_types = [
  {
    value: 'tropical lluvioso de selva',
    label: { es: 'Tropical lluvioso de selva', en: '', fr: '', pt: '' },
  },
  {
    value: 'tropical con lluvias de monzón',
    label: { es: 'Tropical con lluvias de monzón', en: '', fr: '', pt: '' },
  },
  { value: 'tropical de sabana', label: { es: 'Tropical de sabana', en: '', fr: '', pt: '' } },
  { value: 'seco estepario', label: { es: 'Seco estepario', en: '', fr: '', pt: '' } },
  {
    value: 'seco árido o desértico',
    label: { es: 'Seco árido o desértico', en: '', fr: '', pt: '' },
  },
  { value: 'templado húmedo', label: { es: 'Templado húmedo', en: '', fr: '', pt: '' } },
  {
    value: 'templado del mediterráneo',
    label: { es: 'Templado del mediterráneo', en: '', fr: '', pt: '' },
  },
  { value: 'templado subhúmedo', label: { es: 'Templado subhúmedo', en: '', fr: '', pt: '' } },
  {
    value: 'frío con lluvias todo el año',
    label: { es: 'Frío con lluvias todo el año', en: '', fr: '', pt: '' },
  },
  {
    value: 'frío con veranos lluviosos o boreal',
    label: { es: 'Frío con veranos lluviosos o boreal', en: '', fr: '', pt: '' },
  },
  { value: 'frío con verano seco', label: { es: 'Frío con verano seco', en: '', fr: '', pt: '' } },
  { value: 'polar de tundra', label: { es: 'Polar de tundra', en: '', fr: '', pt: '' } },
  {
    value: 'polar de hielos perpetuos',
    label: { es: 'Polar de hielos perpetuos', en: '', fr: '', pt: '' },
  },
];

export const months = [
  { value: 'enero', label: { es: 'Enero', en: 'January' } },
  { value: 'febrero', label: { es: 'Febrero', en: 'February' } },
  { value: 'marzo', label: { es: 'Marzo', en: 'March' } },
  { value: 'abril', label: { es: 'Abril', en: 'April' } },
  { value: 'mayo', label: { es: 'Mayo', en: 'May' } },
  { value: 'junio', label: { es: 'Junio', en: 'June' } },
  { value: 'julio', label: { es: 'Julio', en: 'July' } },
  { value: 'agosto', label: { es: 'Agosto', en: 'August' } },
  { value: 'septiembre', label: { es: 'Septiembre', en: 'September' } },
  { value: 'octubre', label: { es: 'Octubre', en: 'October' } },
  { value: 'noviembre', label: { es: 'Noviembre', en: 'November' } },
  { value: 'diciembre', label: { es: 'Diciembre', en: 'December' } },
];

export const currencies = [
  {
    value: 'MXN',
    label: { es: 'MXN', en: 'MXN', fr: 'MXN', pt: 'MXN' },
    name: {
      es: 'pesos mexicanos',
      en: 'mexican pesos',
      fr: 'pesos mexicains',
      pt: 'pesos mexicanos',
    },
  },
  {
    value: 'EUR',
    label: { es: 'EUR', en: 'EUR', fr: 'EUR', pt: 'EUR' },
    name: { es: 'euros', en: 'euros', fr: 'euros', pt: 'euros' },
  },
  {
    value: 'USD',
    label: { es: 'USD', en: 'USD', fr: 'USD', pt: 'USD' },
    name: { es: 'dólares', en: 'dollars', fr: 'dollars', pt: 'dólares' },
  },
];

export const speciesClasification = [
  { value: 'endémica', label: { es: 'Endémica', en: 'Endemic', fr: 'Endémique', pt: 'Endêmica' } },
  { value: 'invasora', label: { es: 'Invasora', en: 'Invasive', fr: 'Invasif', pt: 'Invasiva' } },
  {
    value: 'migratoria',
    label: { es: 'Migratoria', en: 'Migratory', fr: 'Migrateur', pt: 'Migratória' },
  },
];
