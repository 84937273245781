import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from './components/Loaders/Loader';
import AvoidIfLoggedIn from './components/common/AvoidIfLoggedin';
import ProtectedRoute from './components/common/ProtectedRoute';

const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Auth = lazy(() => import('./pages/Auth'));
const AdminLayout = lazy(() => import('./layouts/AdminLayout'));

const OrganizationsList = lazy(() => import('./pages/Organizations/OrganizationsList'));
const NewOrganization = lazy(() => import('./pages/Organizations/NewOrganization'));

const AdminsList = lazy(() => import('./pages/Admins/AdminsList'));
const FormAdmin = lazy(() => import('./pages/Admins/FormAdmin'));

const UsersList = lazy(() => import('./pages/Users/UsersList'));
const FormUser = lazy(() => import('./pages/Users/FormUser'));

const AreasNaturalesList = lazy(() => import('./pages/Areas/AreaList'));
const NewAreaForm = lazy(() => import('./pages/Areas/NewAreaForm'));

const DestinationList = lazy(() => import('./pages/Destinations/DestinationList'));
const DestinationForm = lazy(() => import('./pages/Destinations/DestinationForm'));

const ReservationList = lazy(() => import('./pages/Reservations/ReservationList'));

const OrdersList = lazy(() => import('./pages/Orders/OrdersList'));

const ExperiencesList = lazy(() => import('./pages/Experiences/ExperiencesList'));
const ExperienceForm = lazy(() => import('./pages/Experiences/ExperiencesForm'));

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader cover />}>
      <Routes>
        <Route
          path='/'
          element={
            <AvoidIfLoggedIn>
              <Auth />
            </AvoidIfLoggedIn>
          }
        />
        <Route path='/cambiar-contrasena' element={<ResetPassword />} />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<h1>admin dashboard stats</h1>}></Route>
          <Route path='organizaciones'>
            <Route index element={<OrganizationsList />} />
            <Route path='formulario' element={<NewOrganization />} />
          </Route>

          <Route path='administradores'>
            <Route index element={<AdminsList />} />
            <Route path='formulario' element={<FormAdmin />} />
          </Route>

          <Route path='areas-naturales'>
            <Route index element={<AreasNaturalesList />} />
            <Route path='formulario' element={<NewAreaForm />} />
          </Route>

          <Route path='destinos'>
            <Route index element={<DestinationList />} />
            <Route path='formulario' element={<DestinationForm />} />
          </Route>

          <Route path='experiencias'>
            <Route index element={<ExperiencesList />} />
            <Route path='formulario' element={<ExperienceForm />} />
          </Route>

          <Route path='reservaciones'>
            <Route index element={<ReservationList />} />
          </Route>

          <Route path='ordenes'>
            <Route index element={<OrdersList />} />
          </Route>

          <Route path='usuarios'>
            <Route index element={<UsersList />} />
            <Route path='formulario' element={<FormUser />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
